import { IdeaStatus } from "api-shared";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../translations/main-translations";

export function useIdeaStatusTranslation() {
    const { t } = useTranslation();

    return useCallback(
        (status: IdeaStatus): string => {
            switch (status) {
                case IdeaStatus.CONVERTED:
                    return t(translationKeys.VDLANG_IDEAS_CONVERTED);
                case IdeaStatus.OPEN:
                    return t(translationKeys.VDLANG_IDEAS_OPEN);
                case IdeaStatus.DISCARDED:
                    return t(translationKeys.VDLANG_IDEAS_DISCARDED);
            }
        },
        [t],
    );
}
